var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.isLoading)?_c('div',[_c('div',[(!_vm.isCaseOpened)?_c('CaseInfo',{attrs:{"caseId":_vm._case.id,"name":_vm._case.name,"description":_vm._case.description,"mana":_vm._case.isManaCase,"imageUrl":_vm._case.image}}):_vm._e(),(_vm.isCaseOpened && _vm.showPrizeItem)?_c('CasePrizeInfo',{attrs:{"item":_vm.prizeItem,"isFarmCase":_vm._case.isFarmCase}}):_vm._e(),_c('div',{staticClass:"case-opener"},[_c('div',{staticClass:"case-opener__roulette"},[_c('div',{staticClass:"_shades _shades--left"}),_c('div',{staticClass:"_shades _shades--right"}),_c('div',{class:{
            'case-opener__roulette__tape': true,
            'scroll-demo': _vm.scrollDemo,
            'scroll-roulette': _vm.openingInProgress,
            '--finished': _vm.isCaseOpened,
          },style:(_vm.stylesWithShift())},_vm._l((_vm.tapeItems),function(item,index){return (item)?_c('div',{key:index,class:{
              'item-info': true,
              ['index--' + index]: true,
              '--wait': !_vm.isImagesLoaded,
            }},[_c('ItemImage',{staticClass:"item-info__image",attrs:{"item":item,"noLazy":true}}),_c('div',{staticClass:"item-info__shadow",style:('box-shadow: inset 0px -10px 15px 1px #' +
                _vm.getColor(item.color))})],1):_vm._e()}),0),(!_vm.isCaseOpened)?_c('span',{staticClass:"_center"}):_vm._e(),(_vm.isCaseOpened && _vm.prizeItem)?_c('div',{staticClass:"item-info item-info--prize"},[_c('ItemImage',{staticClass:"item-info__image",attrs:{"item":_vm.prizeItem}}),_c('div',{staticClass:"item-info__border",style:('border-color: #' +
              _vm.prizeItem.color +
              '; box-shadow: inset 0px -6px 50px -5px #' +
              _vm.prizeItem.color +
              ';')})],1):_vm._e()]),(!_vm.isCaseOpened)?_c('div',[_c('div',{staticClass:"case-opener__bonus"},[_c('Hint',{attrs:{"points":_vm._case.eventPoints}})],1),_c('div',{staticClass:"case-opener__start"},[_c('div',[(_vm.openingInProgress)?_c('Button',{attrs:{"type":"orange","size":"l","text":"Пропустить анимацию"},nativeOn:{"click":function($event){return _vm.stopOpening()}}}):(!_vm.user)?_c('Button',{attrs:{"type":"orange","size":"l","text":"Пробное открытие"},nativeOn:{"click":function($event){return _vm.openCase.apply(null, arguments)}}}):(_vm.hasBonusCase || _vm._case.isFarmCase)?_c('div',{staticClass:"case-opener__start__free"},[(_vm.hasBonusCase)?_c('span',{staticClass:"_prev-price"},[_vm._v(_vm._s(_vm._case.price)+" "),_c('span',{staticClass:"coin --middle",style:({ transform: 'translate(3px, 0)' })})]):_vm._e(),_c('Button',{attrs:{"type":"green","text":_vm.openButtonText,"size":"l"},nativeOn:{"click":function($event){return _vm.openCase('normal')}}})],1):_c('Button',{attrs:{"type":"orange","size":"l","text":_vm.openButtonText},nativeOn:{"click":function($event){return _vm.openCase('normal')}}})],1)])]):_vm._e(),(_vm.prizeItem && _vm.showPrizeItem)?_c('PrizeOptions',{attrs:{"item":_vm.prizeItem,"isFarmCase":_vm._case.isFarmCase},on:{"back":function($event){return _vm.openAgain()}}}):_vm._e()],1)],1),_c('RareItems',{attrs:{"items":_vm.rareItems}}),_c('AllItems',{attrs:{"items":_vm.otherItems}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }