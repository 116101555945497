
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Item as IItem } from '@/types/Item';
import TextHeader from '@/components/Layout/TextHeader.vue';
import Item from '@/components/Item/Item.vue';

@Component({
  components: {
    TextHeader,
    Item
  }
})
export default class RareItems extends Vue {
  @Prop() items: IItem[];
}
