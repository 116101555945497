
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Item } from '@/types/Item';
import TextHeader from '@/components/Layout/TextHeader.vue';

@Component({
  components: {
    TextHeader,
  },
})
export default class CasePrizeInfo extends Vue {
  @Prop() item: Item;
  @Prop() isFarmCase: Boolean;

  get user() {
    return this.$store.getters['user/isAuthorized']
      ? this.$store.state.user
      : null;
  }
}
