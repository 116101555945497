import Axios from 'axios';

import { InventoryItem } from '@/types/Item';
import { CompletedTaskNotificationResponse } from '@/requests/types';
import { MOCK_OPEN_CASE_RESPONSE } from '@/requests/Cases/openCase/mockOpenCase';
import { UserAccount } from '@/types/User';

export interface OpenCaseResponse {
    error?: string;
    user?: Partial<UserAccount>;
    winItem?: InventoryItem;
    eventCompletedTaskNotification?: CompletedTaskNotificationResponse;
}

export default async function openCase(id: number): Promise<OpenCaseResponse> {
    if (process.env.NODE_ENV === 'development') {
        return MOCK_OPEN_CASE_RESPONSE;
    }

    return (await Axios.post<OpenCaseResponse>('/api/case/open/' + id)).data;
}
