
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class Hint extends Vue {
  @Prop({ type: Number, required: false }) points?: number;

  get randomHint() {
    return Math.floor(Math.random() * 3);
  }
}
