import Axios from 'axios';
import { MOCK_SELL_ITEM } from '@/requests/Withdraw/sellItem/mockSellItem';
import { UserAccount } from '@/types/User';

export interface SellItemResponse {
    error?: string;
    user?: Partial<UserAccount>;
}

export default async function sellItem(dropId: number): Promise<SellItemResponse> {
    if (process.env.NODE_ENV === 'development') {
        return MOCK_SELL_ITEM;
    }

    const response = await Axios.post('/api/drop/sell', {id: dropId});

    const {error, user} = response.data;

    return {error, user};
}
