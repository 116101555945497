import { Case } from '@/types/Case';

export const MOCK_GET_CASE: Case = {
  id: 10001,
  name: 'Гарантированная аркана',
  price: 0,
  image: '',
  label: 'Популярный',
  description:
    'Хуета которую напишу в адпминке типа  - Боевой кейс рошана был создан великими умами! Рошан это такой чел короче он чета там украл сыр или аегу и его',
  eventPoints: 0,
  isFarmCase: false,
  // activations?: {
  //   current: number;
  //   max: number;
  // };
  items: [
    {
      id: 1,
      name: 'Axe',
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxpg5PRUnCCPOs1c3aRxJjJAZVv67rKFMz0vLOd2gXvIXil9XZzvKhZ72CwmoCupQm2OzD84ij3AHmrkA6NXezetFuB52vBg',
      price: 350,
      color: 'ade55c',
      type: 'item',
    },
    {
      id: 1,
      name: 'Axe',
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxpg5PRUnCCPOs1c3aRxJjJAZVv67rKFMz0vLOd2gXvIXil9XZzvKhZ72CwmoCupQm2OzD84ij3AHmrkA6NXezetFuB52vBg',
      price: 350,
      color: 'e4ae39',
      type: 'item',
    },
    {
      id: 1,
      name: 'Axe',
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxpg5PRUnCCPOs1c3aRxJjJAZVv67rKFMz0vLOd2gXvIXil9XZzvKhZ72CwmoCupQm2OzD84ij3AHmrkA6NXezetFuB52vBg',
      price: 350,
      color: '4b69ff',
      type: 'item',
    },
    {
      id: 1,
      name: 'Axe',
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxpg5PRUnCCPOs1c3aRxJjJAZVv67rKFMz0vLOd2gXvIXil9XZzvKhZ72CwmoCupQm2OzD84ij3AHmrkA6NXezetFuB52vBg',
      price: 350,
      color: 'e4ae39',
      type: 'item',
    },
    {
      id: 1,
      name: 'Axe',
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxpg5PRUnCCPOs1c3aRxJjJAZVv67rKFMz0vLOd2gXvIXil9XZzvKhZ72CwmoCupQm2OzD84ij3AHmrkA6NXezetFuB52vBg',
      price: 350,
      color: '4b69ff',
      type: 'item',
    },
    {
      id: 1,
      name: 'Axe',
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxpg5PRUnCCPOs1c3aRxJjJAZVv67rKFMz0vLOd2gXvIXil9XZzvKhZ72CwmoCupQm2OzD84ij3AHmrkA6NXezetFuB52vBg',
      price: 350,
      color: '4b69ff',
      type: 'item',
    },
    {
      id: 1,
      name: 'Axe',
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxpg5PRUnCCPOs1c3aRxJjJAZVv67rKFMz0vLOd2gXvIXil9XZzvKhZ72CwmoCupQm2OzD84ij3AHmrkA6NXezetFuB52vBg',
      price: 350,
      color: '4b69ff',
      type: 'item',
    },
    {
      id: 1,
      name: 'Axe',
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxpg5PRUnCCPOs1c3aRxJjJAZVv67rKFMz0vLOd2gXvIXil9XZzvKhZ72CwmoCupQm2OzD84ij3AHmrkA6NXezetFuB52vBg',
      price: 350,
      color: '4b69ff',
      type: 'item',
    },
    {
      id: 1,
      name: 'Axe',
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxpg5PRUnCCPOs1c3aRxJjJAZVv67rKFMz0vLOd2gXvIXil9XZzvKhZ72CwmoCupQm2OzD84ij3AHmrkA6NXezetFuB52vBg',
      price: 350,
      color: '4b69ff',
      type: 'item',
    },
    {
      id: 1,
      name: 'Axe',
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxpg5PRUnCCPOs1c3aRxJjJAZVv67rKFMz0vLOd2gXvIXil9XZzvKhZ72CwmoCupQm2OzD84ij3AHmrkA6NXezetFuB52vBg',
      price: 350,
      color: 'e4ae39',
      type: 'item',
    },
    {
      id: 1,
      name: 'Axe',
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxpg5PRUnCCPOs1c3aRxJjJAZVv67rKFMz0vLOd2gXvIXil9XZzvKhZ72CwmoCupQm2OzD84ij3AHmrkA6NXezetFuB52vBg',
      price: 350,
      color: '4b69ff',
      type: 'item',
    },
    {
      id: 1,
      name: 'Axe',
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUivB9aSQPZSumixdvSX2J1NwkYv7eoJBV00v_3cC9Poo7izNeJkaHyYuiIwTMI7Jdw2L6Yrduk0Q2wqRU9Zmz1JoLDcFQ_Yg7Oug_pN4EPgYc/200fx132f',
      price: 350,
      color: '4b69ff',
      type: 'item',
    },
    {
      id: 1,
      name: 'Axe',
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUzpxhUSULEeOW9x87BWkl9KgoYt7SmIgJux8zJYS1D_tWinY-FqPHmPKHUmFRU_sx9tf3A_IC6iVq3ohYyJwaiZMbEMF96OAeOlQf8nqS62_np-sSVpjYy43w87C7fyhCygB9ObeVrh_fNHFjNU6AaH6PTA3HQuZh6GiX4S0jkzTRSxO-6VOtLGDWDjQ/200fx132f',
      price: 350,
      color: 'e4ae39',
      type: 'item',
    },
    {
      id: 1,
      name: 'Axe',
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXA7hlNJ48g5hZYS03vQPao0tnaX2J3KhFFv7-3ZApl1PL3di9H6cq_mL-ImOLmOr7D2DtXvJIm3OrErdTzjFKwrhFqajj1d4DAdwU-MFzV81S2xebqgZG6u8_XiSw0KNZgFsk/200fx132f',
      price: 350,
      color: '4b69ff',
      type: 'item',
    },
  ],
};
