
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Item as IItem } from '@/types/Item';
import Item from '@/components/Item/Item.vue';
import LoginGeneralButton from '@/components/Buttons/LoginGeneralButton.vue';
import { UserModule } from '@/store/modules/user';

@Component({
  components: {
    Item,
    LoginGeneralButton
  }
})
export default class AllItems extends Vue {
  @Prop() items: IItem[];

  get user() {
    return this.$store.getters['user/isAuthorized'] ? this.$store.state.user : null;
  }
}
