import { render, staticRenderFns } from "./CasePrizeInfo.vue?vue&type=template&id=fd7403de&scoped=true&"
import script from "./CasePrizeInfo.vue?vue&type=script&lang=ts&"
export * from "./CasePrizeInfo.vue?vue&type=script&lang=ts&"
import style0 from "./CasePrizeInfo.vue?vue&type=style&index=0&id=fd7403de&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd7403de",
  null
  
)

export default component.exports