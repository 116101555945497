import { ruDeclension } from '@/helpers/ruDeclension';
import { CompletedTaskNotificationResponse } from '@/requests/types';

export const getEventNotification = (
  taskData: CompletedTaskNotificationResponse
) => {
  return {
    type: 'success',
    title: 'Поздравляем!',
    text: `Вы выполнили задание <span class="_accent">“${
      taskData.name
    }”</span> за которое получили ${
      taskData.rewards.eventPoints
    } ${ruDeclension(taskData.rewards.eventPoints, [
      'очко',
      'очка',
      'очков'
    ])} и другие призы. Новое задание уже ждет тебя в нашем <router-link to="/event">Приключении</router-link>!`,
    group: 'notification'
  };
};
