
import { Component, Prop, Vue } from 'vue-property-decorator';
import TextHeader from '@/components/Layout/TextHeader.vue';
import CaseImage from '@/components/Case/CaseImage.vue';
import { Case } from '@/types/Case';
import { getRandomIntInclusive } from '@/helpers/getRandomIntInclusive';
import { tr } from 'date-fns/locale';

@Component({
  components: {
    CaseImage,
    TextHeader,
  },
})
export default class CaseInfo extends Vue {
  @Prop({ required: true }) caseId: number;
  @Prop() name: string;
  @Prop() imageUrl: string;
  @Prop() mana: boolean | false;
  @Prop({ required: false }) description: string | undefined;

  mounted() {
    this.$store.dispatch('caseCategories/fetch');
  }

  get defaultDescription() {
    return `🔥 Мы обновили этот кейс, но еще не успели добавить для него новое описание.`;
  }

  get adjacentCases() {
    try {
      const now = Date.now();
      const categories = this.$store.state.caseCategories.categories;
      const allCases: Case[] = categories.flatMap(category => category.cases);

      // console.log(now, categories, allCases);

      const currentIndex = allCases.findIndex(c => c.id === this.caseId);
      const defaultCases = {
        prev: allCases[getRandomIntInclusive(0, allCases.length / 2)],
        next: allCases[
          getRandomIntInclusive(allCases.length + 1, allCases.length)
        ],
      };

      if (currentIndex === -1) {
        return defaultCases;
      }

      const prev =
        currentIndex > 0 ? allCases[currentIndex - 1] : defaultCases.prev;
      const next =
        currentIndex < allCases.length - 1
          ? allCases[currentIndex + 1]
          : allCases[
              getRandomIntInclusive(allCases.length + 1, allCases.length)
            ];

      // console.log(now, prev, next);

      return { prev, next };
    } catch (err) {
      return { prev: null, next: null };
    }
  }
}
