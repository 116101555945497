
import { Component, Vue } from 'vue-property-decorator';

import { Case, CaseCategory } from '@/types/Case';
import { Item as IItem } from '@/types/Item';

import openCase from '@/requests/Cases/openCase/openCase';
import getCase from '@/requests/Cases/getCase/getCase';
import { shuffleArray } from '@/helpers/shuffleArray';
import Button from '@/components/Buttons/Button.vue';
import Item from '@/components/Item/Item.vue';
import Hint from '@/components/Case/Hint.vue';
import PrizeOptions from '@/components/Case/PrizeOptions.vue';
import CaseName from '@/components/Case/CaseInfo.vue';
import CaseInfo from '@/components/Case/CaseInfo.vue';
import CasePrizeInfo from '@/components/Case/CasePrizeInfo.vue';
import RareItems from '@/components/Case/RareItems.vue';
import AllItems from '@/components/Case/AllItems.vue';
import { getEventNotification } from '@/helpers/getNotification';
import ItemImage from '@/components/Item/ItemImage.vue';
import { getItemColor } from '@/helpers/getItemColor';
import getCases from '@/requests/Cases/getCases/getCases';
import { getItemImage } from '@/helpers';

@Component({
  components: {
    ItemImage,
    CasePrizeInfo,
    CaseInfo,
    Button,
    Item,
    Hint,
    PrizeOptions,
    CaseName,
    CasePrizeName: CasePrizeInfo,
    RareItems,
    AllItems,
  },
})
export default class CasePage extends Vue {
  openingInProgress: boolean = false;
  isCaseOpened: boolean = false;
  blockCaseOpening: boolean = false;
  isLoading: boolean = true;
  _case: Case;
  prizeItem: IItem = null;
  showPrizeItem: boolean = false;
  tapeItems: IItem[];
  openingTimer;
  categories: CaseCategory[] = [];
  rouletteShift: string;
  rouletteStartMargin: number = 0;
  scrollDemo: boolean = false;
  isImagesLoaded: boolean = false;

  get user() {
    return this.$store.getters['user/isAuthorized']
      ? this.$store.state.user
      : null;
  }

  created() {
    this.$setPageTitle('Кейс ... | DOTALOOT');
    this.calcShift();
    setTimeout(() => {
      this.scrollDemo = true;
    }, 100);
  }

  async mounted() {
    this.categories = await getCases();
    this._case = await getCase(Number(this.$route.params.id));
    this.$setPageTitle(`Кейс «${this._case.name}» | DOTALOOT`);
    this.fillTape();
    this.isLoading = false;
    setTimeout(() => {
      this.preloadItemImages();
    }, 10);
  }

  getColor(color: string) {
    return getItemColor(color);
  }

  stylesWithShift() {
    return {
      '--random-shift': this.rouletteShift,
    };
  }

  calcShift() {
    const plusOrMinus = Math.random() < 0.5 ? -1 : 1;
    const halfWidth = window.innerWidth > 780 ? 100 : 50;
    this.rouletteShift =
      (Math.floor(Math.random() * halfWidth) + 1) * plusOrMinus + 'px';
  }

  get rareItems() {
    return this._case.items
      .sort((a, b) => b.price - a.price)
      .slice(0, this._case.items.length < 15 ? 3 : 5);
  }

  get otherItems() {
    const sortedItems = this._case.items.sort((a, b) => b.price - a.price);

    return shuffleArray(
      sortedItems.slice(
        this._case.items.length < 15 ? 3 : 5,
        sortedItems.length
      )
    );
  }

  fillTape() {
    this.tapeItems = [];
    for (let i = 0; i <= 152; i++) {
      this.tapeItems.push(
        this._case.items[Math.floor(Math.random() * this._case.items.length)]
      );
    }
  }

  get hasBonusCase() {
    return (
      this.user &&
      this.user.bonusCases.find(_case => this._case.id === _case.id)
    );
  }

  get openButtonText() {
    if (this.hasBonusCase) {
      return 'Забрать Подарок';
    }

    if (this._case.isFarmCase || !this._case.price) {
      return 'Открыть Бесплатно';
    }

    return `Открыть кейс за ${this._case.price}
    ${this._case.isManaCase ? ' маны' : ''}`;
  }

  async openCase(mode?: 'demo' | 'normal') {
    if (mode === 'normal') {
      try {
        if (!this._case || this.blockCaseOpening || this.prizeItem) return;
        this.blockCaseOpening = true;
        const { winItem, user, eventCompletedTaskNotification } =
          await openCase(this._case.id);

        if (eventCompletedTaskNotification) {
          this.$notify(getEventNotification(eventCompletedTaskNotification));
        }
        this.$store.commit('user/update', user);
        this.prizeItem = winItem;
        this.startOpening();
      } catch (err) {
        console.error(err);

        this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'error',
          title: 'Открытие кейса',
          text:
            err.response && err.response.data && err.response.data.error
              ? err.response.data.error
              : 'Произошла неизвестная ошибка при открытии кейса. Пожалуйста, попробуйте позже.',
        });
      } finally {
        this.blockCaseOpening = false;
      }
    } else {
      this.prizeItem = this.tapeItems[142];
      this.startOpening();
    }
  }

  startOpening() {
    this.tapeItems[142] = this.prizeItem;

    this.rouletteStartMargin = 0;
    this.scrollDemo = false;
    this.openingInProgress = true;
    const _vm = this;
    this.openingTimer = setTimeout(function () {
      _vm.stopOpening();
    }, 11000);
  }

  stopOpening() {
    this.openingInProgress = false;
    this.isCaseOpened = true;
    this.showPrizeItem = true;
    clearTimeout(this.openingTimer);
  }

  openAgain() {
    this.isCaseOpened = false;
    this.showPrizeItem = false;
    this.prizeItem = null;
    this.fillTape();
    this.calcShift();
    this.scrollDemo = true;
  }

  preloadItemImages() {
    const images = this._case.items.map(item => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = getItemImage(item, { width: 200, height: 132 });
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(images)
      .then(() => {
        this.isImagesLoaded = true;
      })
      .catch(error => {
        console.error(error.message);
      });
  }
}
